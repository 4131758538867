<template>
  <div class="atcSign">
    <h2>
      <i class="iconfont icon-fanhui" @click="gos"></i>教学活动 > 活动管理 >
      抢答
    </h2>
    <!-- 进行中 -->
    <div class="actSignBg pl30 pr30 pt20">
      <ul>
        <li>
          <span class="mark">抢答</span>
          <div>
            抢答<br />
            <em>2022-08-10 17:10</em
            ><span><i class="iconfont icon-time"></i>剩余:9分钟</span>
          </div>
        </li>
      </ul>
      <div class="rushA" v-if="!showFlag">
        <img src="../assets/images/rushAn.png" alt="" />
        <br />
        <el-button type="primary" round @click="showResult">立即抢答</el-button>
      </div>
      <div class="rushAnWrap" v-else>
        <!-- <div class="singleWrap">
          <div class="itemWrap">
            <div class="touxiangWrap">
              <img
                src="../assets/images/touxiang.png"
                alt=""
                class="touxiang"
              />
              <img src="../assets/images/icon_king.png" alt="" class="king" />
            </div>
            <h3>小月牙(123456)</h3>
          </div>
        </div> -->
        <div class="allWrap">
          <div class="itemWrap">
            <div class="touxiangWrap">
              <img
                src="../assets/images/touxiang.png"
                alt=""
                class="touxiang"
              />
              <img src="../assets/images/icon_king.png" alt="" class="king" />
            </div>
            <h3>小月牙(123456)</h3>
          </div>
          <div class="itemWrap">
            <div class="touxiangWrap">
              <img
                src="../assets/images/touxiang.png"
                alt=""
                class="touxiang"
              />
            </div>
            <h3>小月牙(123456)</h3>
          </div>
          <div class="itemWrap">
            <div class="touxiangWrap">
              <img src="../assets/images/rushDai.png" alt="" class="touxiang" />
            </div>
            <h3 class="colorCcc">待加入</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- 已结束 -->
    <!-- <div class="actSignBg pl30 pr30 pt20">
      <ul class="end">
        <li>
          <span class="mark">抢答</span>
          <div>
            抢答<br />
            <em>2022-08-10 17:10</em><span>已结束</span>
          </div>
        </li>
      </ul>
      <div class="rushANodata">
        <img src="../assets/images/rushANodata.png" alt="" />
      </div>
      <div class="singleWrap">
        <div class="itemWrap">
          <div class="touxiangWrap">
            <img src="../assets/images/touxiang.png" alt="" class="touxiang" />
            <img src="../assets/images/icon_king.png" alt="" class="king" />
          </div>
          <h3>小月牙(123456)</h3>
        </div>
      </div>
      <div class="allWrap">
        <div class="itemWrap">
          <div class="touxiangWrap">
            <img src="../assets/images/touxiang.png" alt="" class="touxiang" />
            <img src="../assets/images/icon_king.png" alt="" class="king" />
          </div>
          <h3>小月牙(123456)</h3>
        </div>
        <div class="itemWrap">
          <div class="touxiangWrap">
            <img src="../assets/images/touxiang.png" alt="" class="touxiang" />
          </div>
          <h3>小月牙(123456)</h3>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFlag: true,
    };
  },
  methods: {
    gos() {
      this.$router.go(0);
    },
    showResult() {
      this.showFlag = true;
    },
  },
};
</script>

<style scoped lang="scss">
.atcSign {
  height: 100%;
  box-sizing: border-box;
  h2 {
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 16px;
    i {
      margin-right: 10px;
      color: #787d84;
      vertical-align: middle;
    }
  }
  .actSignBg {
    height: calc(100% - 41px);
    box-sizing: border-box;
    background: #fff;
    border-radius: 3px;
    li {
      height: 75px;
      display: flex;
      padding-top: 16px;
      box-sizing: border-box;
      .mark {
        font-size: 13px;
        color: #fff;
        border-radius: 4px;
        background: #507fff;
        text-align: center;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-right: 16px;
      }
      div {
        border-bottom: 1px solid #eef1f3;
        font-size: 14px;
        cursor: pointer;
        flex: 1;
        span {
          font-size: 13px;
          color: #ff7d4e;
        }
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        em {
          color: #666666;
          font-size: 13px;
          font-style: normal;
          margin-right: 10px;
        }
      }
    }
    .end li .mark {
      background: #e5e5e5;
      color: #999999;
    }
    .end li div {
      span {
        font-size: 13px;
        color: #666;
      }
    }
    .qdcg {
      width: 174px;
      text-align: center;
      color: #999999;
      font-size: 13px;
      line-height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      h2 {
        font-size: 30px;
      }
    }
  }
}
.rushA {
  padding-top: 50px;
  width: 100%;
  text-align: center;
  img {
    margin-bottom: 29px;
  }
  .el-button {
    width: 225px;
    font-weight: bold;
  }
}
.rushAnWrap {
}
.rushANodata {
  padding-top: 160px;
  width: 100%;
  text-align: center;
}
.singleWrap {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.allWrap {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}
.itemWrap {
  width: 245px;
  height: 230px;
  padding: 60px 53px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.02);
  margin-left: 30px;
  margin-bottom: 30px;

  text-align: center;
  .touxiangWrap {
    position: relative;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 23px;
    img.touxiang {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    img.king {
      position: absolute;
      left: 50%;
      top: -35px;
      width: 40px;
      height: 40px;
      margin-left: -20px;
    }
  }

  h3 {
    font-size: 16px;
  }
  h3.colorCcc {
    color: #ccc;
  }
}
</style>
