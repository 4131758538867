<template>
	<div class="atcSign">
		<h2>
			<i class="iconfont icon-fanhui" @click="gos"></i>教学活动 > 活动管理
			> 随堂测验
		</h2>
		<ul class="ul">
			<li>
				<span :class="type == 1 ? 'mark' : 'marks'"
					>随堂<br />测验</span
				>
				<div>
					{{ title }}<br />
					<em>{{ str_time | formatimes('yyyy-MM-dd hh:mm') }}</em>
					<span :class="type == 1 ? 'colorOrange' : ''">{{
						type == 1 ? '已提交' : '已结束'
					}}</span>
				</div>
			</li>
		</ul>
		<div class="questionsSign">
			<main>
				<div class="mainWrap">
					<div class="questionstishi">
						<div
							v-for="(item, index) in question_infos"
							:key="index"
						>
							<!-- 单选 -->
							<Radio
								class="itemWrap"
								v-if="item.retype == 1"
								:list="item"
								:is_check_asw="
									Parper.is_check_asw
								"
							></Radio>
							<!-- 多选 -->
							<Checkbox
								class="itemWrap"
								v-if=" item.retype == 2|| item.retype == 3
								"
								:list="item"
								:is_check_asw="
									Parper.is_check_asw
								"
							></Checkbox>
							<!-- 判断 -->
							<Estimate
								class="itemWrap"
								v-if="item.retype == 5"
								:list="item"
								:is_check_asw="
									Parper.is_check_asw
								"
							></Estimate>
							<!-- 论述 -->
							<Discuss
								class="itemWrap"
								v-if=" item.retype == 4"
								:list="item"
								:is_check_asw="
									Parper.is_check_asw
								"
							></Discuss>
						</div>
					</div>
				</div>
			</main>
		</div>
<!--		<el-button-->
<!--			type="info"-->
<!--			style="margin: 0 auto; display: block"-->
<!--			v-if="type != 1"-->
<!--			@click="-->
<!--				$parent.sign = true;-->
<!--				$parent.getList();-->
<!--			"-->
<!--			>已结束</el-button-->
<!--		>-->
<!--		<el-button-->
<!--			type="primary"-->
<!--			style="margin: 0 auto; display: block"-->
<!--			v-else-->
<!--			@click="-->
<!--				$parent.sign = true;-->
<!--				$parent.getList();-->
<!--			"-->
<!--			>返回</el-button-->
<!--		>-->
	</div>
</template>

<script>
import Checkbox from './comReport/Checkbox'; // 多选
import Radio from './comReport/Radio'; // 单选
import Estimate from './comReport/Estimate'; // 判断
import Discuss from './comReport/Discuss'; // 论述
import { paperInfo } from '../assets/api';
export default {
	name: 'classQuiz_report',
	components: { Checkbox, Radio, Estimate, Discuss },
	data() {
		return {
			title: '',
			str_time: '',
			type: '',
			//
			question_infos: [],
			questionData: {},
			num: '',
			seconds: 0,
			upTime: '',
			downTime: '',
			bfIshow: true,
			answerTotal: [],
			dataDetail: {},
			show_status: 0,
			Parper: {},
			ExaminationSituationShow: false,
			extar: {},
		};
	},
	mounted() {
		// this.getpaperList();
	},
	methods: {
		gos() {
			this.$router.go(0);
		},

		//数据处理ZQ
		dataProcessing(a, b, c, d) {
			a.map((item) => {
				if (item.retype == 1 || item.retype == 5 || item.retype == 4) {
					this.$set(item, 'radio', '');
					if (item.user_answer != '') {
						this.$set(item, 'radio', item.user_answer);
					}
					if (item.retype == 1 || item.retype == 5) {
						this.radioOptions(item);
					}
				} else if (item.retype == 2 || item.retype == 3) {
					this.$set(item, 'radio', []);
					if (item.user_answer != '') {
						var ss = item.user_answer.split(',');
						this.$set(item, 'radio', ss);
					}
					this.sub(item);
				}
				this.Question_list.push(item);
				this.$nextTick(() => {
					if (item.is_stuff > 0) {
						c.map((item1) => {
							if (item1.stuff_id == item.stuff_id) {
								if (!item1.list) {
									this.$set(item1, 'list', []);
								}
								this.$set(item1, 'section_id', item.section_id);
								item1.list.push(item);
								b.push(item1);
								if (b.length > 0) {
									b = this.unique(b);
								}
							}
						});
					} else {
						b.push(item);
					}
				});
				this.$nextTick(() => {
					if (d != undefined && d.length > 0) {
						d.map((itembf) => {
							this.$set(itembf, 'timu', []);
							b.map((item) => {
								if (itembf.section_id == item.section_id) {
									itembf.timu.push(item);
								}
							});
						});
						this.question_infos = d;
						this.bfIshow = true;
					} else {
						this.question_infos = b;
						this.bfIshow = false;
					}
				});
			});
		},
		//多选高亮处理
		sub(list1) {
			this.$set(list1, 'subFlag', true);
			list1.result = list1.result.split(',');
			if (list1.radio.length > 0) {
				list1.radio.sort();
				let check = JSON.parse(JSON.stringify(list1.radio.sort()));
				if (list1.result.toString() == check.toString()) {
					this.$set(list1, 'flag', '回答正确');
					list1.radio.map((item) => {
						list1.options.map((ite) => {
							if (item == ite.option_mark) {
								this.$set(ite, 'className', 'active');
							}
						});
					});
				} else {
					this.$set(list1, 'flag', '回答错误');
					list1.options.map((ite) => {
						list1.radio.map(() => {
							if (
								ite.is_correct == 1 &&
								list1.radio.indexOf(ite.option_mark) != -1
							) {
								this.$set(ite, 'className', 'active');
							} else if (
								ite.is_correct == 1 &&
								list1.radio.indexOf(ite.option_mark) == -1
							) {
								this.$set(ite, 'className', 'right');
							} else if (
								list1.radio.indexOf(ite.option_mark) != -1
							) {
								this.$set(ite, 'className', 'error');
							} else {
								this.$set(ite, 'className', '');
							}
						});
					});
				}
			} else {
				list1.options.map((ite) => {
					list1.result.map((item) => {
						if (item == ite.option_mark) {
							this.$set(ite, 'className', 'right');
						}
					});
				});
				this.$set(list1, 'flag', '回答错误');
			}
		},
		//单选、判断高亮处理
		radioOptions(list1) {
			if (list1.user_answer != '') {
				list1.options.forEach((item) => {
					if (
						item.option_mark != list1.result &&
						list1.user_answer == item.option_mark
					) {
						this.$set(item, 'className', 'error');
						this.$set(list1, 'flag', '回答错误');
					} else if (
						item.option_mark == list1.result &&
						list1.user_answer == item.option_mark
					) {
						this.$set(item, 'className', 'active');
						this.$set(list1, 'flag', '回答正确');
					} else if (
						item.option_mark == list1.result &&
						list1.user_answer != item.option_mark
					) {
						this.$set(item, 'className', 'right');
						this.$set(list1, 'flag', '回答错误');
					}
				});
			}
		},
		unique(arr) {
			for (var i = 0; i < arr.length; i++) {
				for (var j = i + 1; j < arr.length; j++) {
					if (arr[i].is_stuff == undefined) {
						if (arr[i].stuff_id == arr[j].stuff_id) {
							arr.splice(j, 1);
							j--;
						}
					}
				}
			}
			return arr;
		},
		getpaperList(id) {
			const params = {
				id: id,
				course_id: this.$route.query.course_id,
				cst_id: this.$route.query.cst_id,
			};
			paperInfo(params).then((res) => {
				if (res.code == 0) {
					if (res.data.paper_show.question_show_list.length > 0) {
						let b = [];
						this.Question_list = res.data.paper_show.question_show_list;
						// this.collect_id = res.data.collect_id;
						this.Parper = res.data.paper_show;
						// this.extar = res.data.Extar;
						this.dataProcessing(
							res.data.paper_show.question_show_list,
							b,
							// res.data.Stuffinfos,
							// res.data.Sectioninfos
						);
					} else {
						this.$message.error('暂无试题');
					}
				}
			});
			//   let extar = {};
			//   let par = {
			//     extar: JSON.stringify(extar),
			//     show_status: 0,
			//     paper_id: 0,
			//     answer_id: 994,
			//   };
			//   paperinfo(par).then((res) => {
			//     if (res.code == 0) {
			//       if (res.data.Question_list.length > 0) {
			//         let b = [];
			//         this.Question_list = res.data.Question_list;
			//         this.collect_id = res.data.collect_id;
			//         this.Parper = res.data.Paper;
			//         this.extar = res.data.Extar;
			//         this.dataProcessing(
			//           res.data.Question_list,
			//           b,
			//           res.data.Stuffinfos,
			//           res.data.Sectioninfos
			//         );
			//       } else {
			//         this.$message.error("暂无试题");
			//       }
			//     }
			//   });
		},
	},
};
</script>

<style scoped lang="scss">
.atcSign {
	height: 100%;
	box-sizing: border-box;
	h2 {
		font-weight: normal;
		font-size: 14px;
		padding-bottom: 16px;
		i {
			margin-right: 10px;
			color: #787d84;
			vertical-align: middle;
		}
	}
	.ul {
		background: #fff;
		padding: 0 30px;
		margin-bottom: 20px;
		border-radius: 3px;
		li {
			height: 75px;
			display: flex;
			padding-top: 16px;
			box-sizing: border-box;
			.mark {
				font-size: 13px;
				color: #fff;
				border-radius: 4px;
				background: #00c292;
				text-align: center;
				width: 42px;
				height: 42px;
				line-height: 16px;
				margin-right: 16px;
				box-sizing: border-box;
				padding-top: 5px;
			}
			.marks {
				font-size: 13px;
				color: #999;
				border-radius: 4px;
				background: #e5e5e5;
				text-align: center;
				width: 42px;
				height: 42px;
				line-height: 16px;
				margin-right: 16px;
				box-sizing: border-box;
				padding-top: 5px;
			}
			div {
				font-size: 14px;
				cursor: pointer;
				flex: 1;
				span.colorOrange {
					font-size: 13px;
					color: #ff7d4e;
				}
				i {
					font-size: 13px;
					margin-right: 5px;
				}
				em {
					color: #666666;
					font-size: 13px;
					font-style: normal;
					margin-right: 10px;
				}
			}
		}
	}
}
</style>
