<template>
  <div class="">
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    openInit(img) {
      this.dialogVisible = true;
      this.dialogImageUrl = img;
    },
  },
};
</script>

<style scoped lang="">
</style>
