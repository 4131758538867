var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"detailThought"},[_vm._m(0),(_vm.thought_dial.rtype == 4)?_c('div',{staticClass:"detailThoughtdiv1"},[_vm._m(1),(
					_vm.thought_dial.user_answer == '' &&
					_vm.thought_dial.user_pic.length == 0
				)?_c('div',{staticStyle:{"color":"#999"}},[_vm._v(" 未作答 ")]):[(_vm.thought_dial.user_answer != '')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.thought_dial.user_answer)}}):_vm._e(),(_vm.thought_dial.user_pic.length > 0)?_c('ul',{staticClass:"detailThoughtdiv1oul clearfix"},_vm._l((_vm.thought_dial.user_pic),function(item,index){return _c('li',{key:index},[(item.url)?_c('img',{attrs:{"src":item.url,"alt":""},on:{"click":function($event){return _vm.checkImg(item.url)}}}):_vm._e()])}),0):_vm._e(),_c('imgDialog',{ref:"ImgDialog"})]],2):(_vm.thought_dial.rtype == 5)?_c('div',{staticClass:"detailThoughtdiv"},[_vm._v(" 回答"+_vm._s(_vm.thought_dial.result.toString() == _vm.thought_dial.user_answer.toString() ? '正确' : '错误')+" ")]):_c('div',{staticClass:"detailThoughtdiv"},[_vm._v(" 正确答案是 "),_c('span',{staticClass:"zq"},[_vm._v(_vm._s(_vm.thought_dial.result))]),_vm._v(" ，你的答案是 "),_c('span',{staticClass:"err"},[_vm._v(_vm._s(_vm.thought_dial.user_answer != '' || _vm.thought_dial.user_answer.length != 0 ? _vm.thought_dial.user_answer : '未作答'))]),_vm._v(" 。"),(
					_vm.thought_dial.result &&
					(_vm.thought_dial.user_answer != '' ||
						_vm.thought_dial.user_answer.length != 0)
				)?_c('span',[_vm._v("回答"+_vm._s(_vm.thought_dial.result.toString() == _vm.thought_dial.user_answer.toString() ? '正确' : '错误'))]):_vm._e()]),(_vm.is_check_asw)?_c('div',{staticClass:"jiexi"},[_c('i',{staticClass:"iconfont icon-jiexi"}),_vm._v(_vm._s(_vm.thought_dial.rtype == 4 ? '参考答案' : '解析')+" ")]):_vm._e(),(_vm.is_check_asw)?_c('div',{staticClass:"thoughtDetail",domProps:{"innerHTML":_vm._s(_vm.thought_dial.analysis)}}):_vm._e()]),(
			_vm.thought_dial.rtype == 4 &&
			(_vm.thought_dial.sub_score != '' || _vm.thought_dial.assess != '')
		)?_c('div',{staticClass:"teacherdp"},[_c('i',{staticClass:"teacherdpi"},[_vm._v("老师点评")]),_c('h6',{staticClass:"teacherdph6"},[_vm._v(" 评分："),_c('span',[_vm._v(_vm._s(_vm.thought_dial.sub_score)+"分")])]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.thought_dial.assess)}})]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('em',{staticClass:"jiantou"},[_c('img',{attrs:{"src":require("../../../assets/images/uxb_cbg1.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',[_c('i',{staticClass:"iconfont icon-lunsu"}),_vm._v("你的答案")])}]

export { render, staticRenderFns }