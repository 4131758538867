<template>
    <div class="main">
    <p class="breadCrumb">{{$route.query.metaType==1?'首页':'学习'}} > 课程</p>
    <div class="bgcol">
        <img src="../assets/images/xaioren.png" alt="">
        <p v-if="in_list==0">抱歉，您无该课学习权限，{{time}}s后自动跳转</p>
        <p v-else>抱歉，该课已结课，无法学习，{{time}}s后自动跳转</p>
        <el-button type="primary" round @click="jump">点我跳转</el-button>
    </div>
    </div>
</template>

<script>
    export default {
        name: "nolearn",
        data(){
            return{
                time:3,
                timerId:null
            }
        },
        props:['in_list'],
        mounted() {
            
        },
        beforeDestroy(){
            clearInterval(this.timerId)
        },
        methods:{
            times(){
                    this.timerId = setInterval(()=>{
                        this.time --
                        if(this.time==0){
                            this.time=0
                            this.jump()
                        }
                    },1000)

            },
            jump(){
                this.$router.push({path:'/learn',query:{course_id:this.$route.query.course_id}})
            }
        },
    }
</script>

<style scoped lang="scss">
    .main{padding-top: 69px}
    .breadCrumb {
        padding: 20px 0;
        box-sizing: border-box;
        font-size: 14px;
    }
.bgcol{
    background: #fff; height: 100%;text-align: center; display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 100px 0 200px;
    p{color: #999; font-size: 18px; line-height: 18px; padding: 20px 0 30px;}
}
</style>
