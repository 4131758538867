<template>
	<!-- 多选 -->
	<div class="checkWrap" :id="'question' + list.question_seq">
		<b class="itemNum">{{ list.question_seq }} <span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
		<div class="itemDetail">
			<div class="detailTitle">
				<span class="detailCate">{{
					list.rtype == 2 ? '多选题' : '不定项选择题'
				}}</span>
				<div class="detailContent" v-html="list.title"></div>
			</div>
			<div class="detailOption">
				<el-checkbox-group v-model="list.user_answer" :disabled="true">
					<el-checkbox
						:label="item.option_mark"
						v-for="(item, index) in list.question_option"
						:key="index"
						:class="item.className"
					>
						<i class="optionIcon">{{ item.option_mark }}</i>
						<div
							class="optionContent"
							v-html="item.option_content"
						></div>
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<Point :thought_dial="list" :is_check_asw="is_check_asw"></Point>
		</div>
		<i></i>
	</div>
</template>

<script>
import Point from './com/Point.vue';

export default {
	props: {
		list: Object,
		is_stuff: Number,
		is_check_asw: Boolean,
	},
	components: {
		Point,
	},
	data() {
		return {};
	},
	mounted() {
		// this.sub()
	},
	methods: {
		sub() {
			this.$set(this.list, 'subFlag', true);
			this.list.result = this.list.result.split(',');
			if (this.list.radio.length > 0) {
				this.list.radio.sort();
				let check = JSON.parse(JSON.stringify(this.list.radio.sort()));
				if (this.list.result.toString() == check.toString()) {
					this.flag = '回答正确';
					this.list.radio.map((item) => {
						this.list.options.map((ite) => {
							if (item == ite.option_mark) {
								this.$set(ite, 'className', 'active');
							}
						});
					});
				} else {
					this.flag = '回答错误';
					this.list.options.map((ite) => {
						this.list.radio.map(() => {
							if (
								ite.is_correct == 1 &&
								this.list.radio.indexOf(ite.option_mark) != -1
							) {
								this.$set(ite, 'className', 'active');
							} else if (
								ite.is_correct == 1 &&
								this.list.radio.indexOf(ite.option_mark) == -1
							) {
								this.$set(ite, 'className', 'right');
							} else if (
								this.list.radio.indexOf(ite.option_mark) != -1
							) {
								this.$set(ite, 'className', 'error');
							} else {
								this.$set(ite, 'className', '');
							}
						});
					});
				}
			} else {
				this.list.options.map((ite) => {
					this.list.result.map((item) => {
						if (item == ite.option_mark) {
							this.$set(ite, 'className', 'right');
						}
					});
				});
				this.flag = '回答错误';
			}
		},
		collect(type) {
			let params = {
				exam_id: this.$store.state.exam_id,
				area_id: this.$store.state.area_id,
				paper_question_id: this.list.id,
				pqtype: 1,
				status: type == 0 ? 1 : 2,
			};
			updatecollect(params).then((res) => {
				if (res.flag == 1) {
					if (this.list.iscollect == 0) {
						this.list.iscollect = 1;
					} else {
						this.list.iscollect = 0;
					}
				}
			});
		},
	},
};
</script>

<style scoped lang=""></style>
