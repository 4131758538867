<template>
	<!-- 判断 -->
	<div class="estimateWrap" :id="'question' + list.question_seq">
		<b class="itemNum">{{ list.question_seq }} <span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
		<div class="itemDetail">
			<div class="detailTitle">
				<span class="detailCate">判断题</span>
				<div class="detailContent" v-html="list.title"></div>
			</div>
			<div class="detailOption">
				<el-radio-group v-model="list.user_answer">
					<el-radio
						:label="item.option_mark"
						v-for="(item, index) in list.question_option"
						:key="index"
						:disabled="true"
						:class="item.className"
					>
						<i class="optionIcon"><i class="el-icon-check"></i></i>
						<div
							class="optionContent"
							v-html="item.option_content"
						></div>
					</el-radio>
				</el-radio-group>
			</div>
			<Point :thought_dial="list" :is_check_asw="is_check_asw"></Point>
		</div>
		<i></i>
	</div>
</template>

<script>
import Point from './com/Point.vue';
export default {
	props: {
		list: Object,
		is_stuff: Number,
		is_check_asw: Boolean,
	},
	components: {
		Point,
	},
	data() {
		return {
			radio: '',
		};
	},
	mounted() {
		// this.radioOptions()
	},
	methods: {
		radioOptions() {
			if (this.list.user_answer != '') {
				this.list.options.forEach((item) => {
					if (
						item.option_mark != this.list.result &&
						this.list.user_answer == item.option_mark
					) {
						this.$set(item, 'className', 'error');
						this.flag = '回答错误';
					} else if (
						item.option_mark == this.list.result &&
						this.list.user_answer == item.option_mark
					) {
						this.$set(item, 'className', 'active');
						this.flag = '回答正确';
					} else if (
						item.option_mark == this.list.result &&
						this.list.user_answer != item.option_mark
					) {
						this.$set(item, 'className', 'right');
						this.flag = '回答错误';
					}
				});
			}
		},
		collect(type) {
			let params = {
				exam_id: this.$store.state.exam_id,
				area_id: this.$store.state.area_id,
				paper_question_id: this.list.id,
				pqtype: 1,
				status: type == 0 ? 1 : 2,
			};
			updatecollect(params).then((res) => {
				if (res.flag == 1) {
					if (this.list.iscollect == 0) {
						this.list.iscollect = 1;
					} else {
						this.list.iscollect = 0;
					}
				}
			});
		},
	},
};
</script>

<style scoped lang=""></style>
