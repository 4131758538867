<template>
	<div>
		<div class="detailThought">
			<em class="jiantou"
				><img src="../../../assets/images/uxb_cbg1.png" alt=""
			/></em>
			<div class="detailThoughtdiv1" v-if="thought_dial.rtype == 4">
				<h6><i class="iconfont icon-lunsu"></i>你的答案</h6>
				<div
					v-if="
						thought_dial.user_answer == '' &&
						thought_dial.user_pic.length == 0
					"
					style="color: #999"
				>
					未作答
				</div>
				<template v-else>
					<div
						v-html="thought_dial.user_answer"
						v-if="thought_dial.user_answer != ''"
					></div>
					<ul
						class="detailThoughtdiv1oul clearfix"
						v-if="thought_dial.user_pic.length > 0"
					>
						<li
							v-for="(item, index) in thought_dial.user_pic"
							:key="index"
						>
							<img
								:src="item.url"
								alt=""
								v-if="item.url"
								@click="checkImg(item.url)"
							/>
						</li>
					</ul>
					<imgDialog ref="ImgDialog"></imgDialog>
				</template>
			</div>
			<div class="detailThoughtdiv" v-else-if="thought_dial.rtype == 5">
				回答{{
					thought_dial.result.toString() ==
					thought_dial.user_answer.toString()
						? '正确'
						: '错误'
				}}
			</div>
			<div class="detailThoughtdiv" v-else>
				正确答案是
				<span class="zq">{{ thought_dial.result }}</span> ，你的答案是
				<span class="err">{{
					thought_dial.user_answer != '' ||
					thought_dial.user_answer.length != 0
						? thought_dial.user_answer
						: '未作答'
				}}</span>
				。<span
					v-if="
						thought_dial.result &&
						(thought_dial.user_answer != '' ||
							thought_dial.user_answer.length != 0)
					"
					>回答{{
						thought_dial.result.toString() ==
						thought_dial.user_answer.toString()
							? '正确'
							: '错误'
					}}</span
				>
			</div>
			<div class="jiexi" v-if="is_check_asw">
				<i class="iconfont icon-jiexi"></i
				>{{ thought_dial.rtype == 4 ? '参考答案' : '解析' }}
			</div>
			<div
				v-if="is_check_asw"
				class="thoughtDetail"
				v-html="thought_dial.analysis"
			></div>
		</div>
		<div
			class="teacherdp"
			v-if="
				thought_dial.rtype == 4 &&
				(thought_dial.sub_score != '' || thought_dial.assess != '')
			"
		>
			<i class="teacherdpi">老师点评</i>
			<h6 class="teacherdph6">
				评分：<span>{{ thought_dial.sub_score }}分</span>
			</h6>
			<div v-html="thought_dial.assess"></div>
		</div>
	</div>
</template>

<script>
import imgDialog from '@p/user/components/com/imgDialog.vue';
export default {
	props: {
		thought_dial: Object,
		is_check_asw: Boolean,
	},
	components: { imgDialog },
	data() {
		return {};
	},
	mounted() {},
	methods: {
		checkImg(data) {
			this.$refs.ImgDialog.openInit(data);
		},
	},
};
</script>

<style scoped lang=""></style>
