<template>
  <div class="actWrap">
    <h2>
      <i class="iconfont icon-fanhui" @click="gos"></i>教学活动 > 活动管理 >
      选人
    </h2>
    <div class="actMainWrap">
      <div class="topWrap">
        <span class="marks">选人</span>
        <div>
          上午第一节课签到<br />
          <em>2022-08-10 17:10</em
          ><span v-if="left_time"
            ><i class="iconfont icon-time"></i>剩余10分钟</span
          >
        </div>
      </div>
      <div class="bottomWrap">
        <div class="singleWrap">
          <el-avatar :src="circleUrl" class="mb20"></el-avatar>
          <h3>刘亦菲（lyf123）</h3>
        </div>
        <!-- <div class="allWrap">
          <el-avatar :src="circleUrl" class="mb20"></el-avatar>
          <h3>刘亦菲（lyf123）</h3>
        </div>
        <div class="allWrap">
          <el-avatar :src="circleUrl" class="mb20"></el-avatar>
          <h3>刘亦菲</h3>
        </div>
        <div class="allWrap">
          <el-avatar :src="circleUrl" class="mb20"></el-avatar>
          <h3>刘亦菲</h3>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "signIn",
  data() {
    return {
      circleUrl: "",
    };
  },
  methods: {
    gos() {
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.actWrap {
  height: 100%;
  box-sizing: border-box;
  h2 {
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 16px;
    i {
      margin-right: 10px;
      color: #787d84;
      vertical-align: middle;
    }
  }
  .actMainWrap {
    height: calc(100% - 41px);
    box-sizing: border-box;
    .topWrap {
      padding: 20px 30px;
      margin-bottom: 20px;
      border-radius: 3px;
      background: #fff;
      display: flex;
      .marks {
        font-size: 13px;
        color: #fff;
        border-radius: 4px;
        background: #507fff;
        text-align: center;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-right: 16px;
        box-sizing: border-box;
      }
      .marksend {
        background: #e5e5e5;
      }
      div {
        font-size: 14px;
        cursor: pointer;
        flex: 1;
        span {
          font-size: 13px;
          color: #ff7d4e;
        }
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        em {
          color: #666666;
          font-size: 13px;
          font-style: normal;
          margin-right: 10px;
        }
      }
    }
    .bottomWrap {
      height: calc(100% - 103px);
      background: #fff;
      padding: 128px 40px 0;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      .singleWrap {
        width: 100%;
        // padding: 68px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .allWrap {
        margin: 0px 45px 80px;
        width: 139px;
        text-align: center;
      }
      .el-avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }
      h3 {
        font-size: 16px;
      }
    }
  }
}
</style>
