<template>
  <div class="actbg">
    <ul class="titleWrap">
      <li
        v-for="item of titleList"
        :key="item.id"
        :class="activeLi == item.id ? 'active' : ''"
        @click="changeLi(item)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="contentWrap">
      <div class="mb10">
        <el-input
          v-model="form.title"
          placeholder="请输入作业标题"
          style="width: 300px"
        ></el-input>
        <el-button type="primary" class="ml10" @click="getList(1)">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
      <div class="homeworkWrap">
        <div class="itemWrap" v-for="(item, index) in dataList" :key="index">
          <div class="leftWrap">
            <h3>
              {{ item.title }}
            </h3>
            <div>
              <span v-if="item.time_limit"
                >截止时间：{{
                  item.time_limit | formatimes("yyyy-MM-dd hh:mm")
                }}</span
              >
              <span class="colorRed" v-if="item.allow_late == 1"
                ><i class="iconfont icon-jinzhi mr8 ml20"></i>禁止迟交</span
              >
              <span class="colorRed" v-if="item.is_call > 0"
                ><i class="iconfont icon-cuijiao mr8 ml20"></i>被催交</span
              >
            </div>
          </div>
          <div class="rightWrap">
            <span class="mr15 colorRed" v-if="item.answer_status == 2"
              >被打回</span
            >
            <span class="mr15 colorRed" v-if="(item.assignment_status==2&&item.allow_late==0)">已迟交</span>
            <template v-if="!(item.assignment_status==2&&item.answer_status==0&&item.allow_late==1)">
              <span class="mr15" v-if="item.answer_status == 0">待提交</span>
            </template>
            <span class="mr15" v-if="item.total_score != ''&&item.assess_status==1">{{
              item.total_score
            }}</span>
            <span class="mr15" v-if="item.answer_status == 1&&item.assess_status==0">待点评</span>
            <el-button
              round
              plain
              class="blueBtn"
              v-if="item.allow_reply == 1||(item.answer_status == 2&&item.time_limit==0)||(item.answer_status == 2&&item.assignment_status==1)"
              @click="handleBtn(item)"
              >重新作答</el-button
            >
            <el-button round plain class="blueBtn" @click="handleBtn(item)" v-if="(item.answer_id == 0&&item.assignment_status!=2)||(item.answer_id == 0&&item.assignment_status==2&&item.allow_late==0)"
              >开始作答</el-button
            ><el-button
              round
              plain
              class="blueBtn"
              v-if="(item.answer_status!=2&&item.answer_id > 0)||(item.answer_status == 2&&item.time_limit&&item.assignment_status!=1&&item.answer_id > 0)||(item.assignment_status==2&&item.answer_status==0&&item.allow_late==1)"
              @click="handleBtn(item)"
              >查看作业</el-button
            >
          </div>
        </div>
      </div>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </div>
    <tipDia ref="tipDia"></tipDia>
  </div>
</template>

<script>
import { homeList } from '../assets/api';
import tipDia from './courseHomework/tipDia.vue';
export default {
	components: { tipDia },
	data() {
		return {
			activeLi: 0,
			titleList: [
				{
					name: '全部',
					id: 0,
				},
				{
					name: '未完成',
					id: 2,
				},
				{
					name: '已完成',
					id: 1,
				},
			],
			form: {
				finish: 0,
				course_id: 0,
				cst_id: 0,
				title: '',
			},
			a: '',
			page: 1,
			btnMessage: '加载更多',
			dataList: [],
		};
	},
	mounted() {
		this.form.course_id = this.$route.query.course_id;
		this.form.cst_id = this.$route.query.cst_id;
		this.getList(1);
	},
	methods: {
		getList(type) {
			let data = Object.assign(this.form, { page: this.page });
			homeList(data).then((res) => {
				if (res.code == 0) {
					if (res.data.list.length > 0) {
            if(type==1){
              this.dataList =res.data.list
            }else{
              this.dataList = this.dataList.concat(res.data.list);
            }
					} else {
            if(type==1){this.dataList =[]}
						this.btnMessage = '暂无更多';
					}
				} else {
					this.page--;
				}
			});
		},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getList();
			}
		},
		reset() {
      this.form.title=''
			this.getList(1);
		},
		changeLi(item) {
			this.activeLi = item.id;
			this.page = 1;
			this.dataList = [];
			this.form.finish = item.id;
			this.getList(1);
		},
		handleBtn(item) {
			this.$refs.tipDia.openInit(item);
		},
		lockReport(item) {
			if (item.iswork == 0) {
				this.$router.push({
					path: '/homework',
					query: {
						assignment_id: item.relation_id,
						id: item.id,
						course_id: item.course_id,
						cst_id: item.cst_id,
						answer_id: item.answer_id,
						newHomework: 1,
						isAnswer: 1,
					},
				});
			} else {
				this.$router.push({
					path: '/examReport',
					query: {
						course_id: this.$route.query.course_id,
						id: item.id,
						answer_id: item.answer_id,
						paper_id: item.relation_id,
						cst_id: this.$route.query.cst_id,
						isExam: 1,
					},
				});
			}
		},
		jumpHomework(data) {
			if (data.iswork == 0) {
				this.$router.push({
					path: '/homework',
					query: {
						assignment_id: data.relation_id,
						id: data.id,
						course_id: data.course_id,
						cst_id: data.cst_id,
						answer_id: data.answer_id,
						newHomework: 2,
						isAnswer: 2,
					},
				});
			} else {
				this.$router.push({
					path: '/studentsExam',
					query: {
						course_id: data.course_id,
						id: data.id,
						answer_id: data.answer_id,
						paper_id: data.relation_id,
						cst_id: this.$route.query.cst_id,
						isExam: 1,
					},
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
.actbg {
	background: #fff;
	border-radius: 3px;
	height: 100%;
	box-sizing: border-box;
}
.titleWrap {
	display: flex;
	height: 60px;
	line-height: 60px;
	border-bottom: 1px solid #eef1f3;
	padding: 0 20px;
	li {
		margin-right: 30px;
		font-size: 16px;
		cursor: pointer;
	}
	li.active {
		color: var(--change-color);
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0;
			width: 32px;
			height: 2px;
			margin-left: -16px;
			background: var(--change-color);
		}
	}
}
.contentWrap {
	padding: 20px 30px;
	.homeworkWrap {
		.itemWrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			color: #888888;
			padding: 21px 0 23px;
			border-bottom: 1px solid #f1f1f1;
			.colorRed {
				color: #f55a5a;
			}
			.leftWrap {
				h3 {
					font-size: 16px;
					color: #333;
					margin-bottom: 14px;
					line-height: 21px;
				}
				div {
					line-height: 19px;
					.colorRed {
						position: relative;
						&::before {
							position: absolute;
							content: '';
							width: 1px;
							height: 12px;
							left: 10px;
							top: 4px;
							background: #ccc;
						}
					}
				}
				i {
					font-size: 12px;
				}
				.mr8 {
					margin-right: 8px;
				}
			}
			.rightWrap {
				.blueBtn {
					border: 1px solid var(--default-color);
					color: var(--default-color);
					padding: 7px 17px;
				}
			}
		}
	}
}
.moreBtn {
	margin: 0 auto;
	margin-top: 30px;
	width: 100px;
	height: 30px;
	font-size: 14px;
	border-radius: 18px;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	color: #666;
	&:hover {
		background: var(--change-color);
		border: 1px solid var(--change-color);
		color: #fff;
	}
	i {
		font-size: 14px;
	}
}
</style>
