<template>
  <div class="actbg">
    <template v-if="dataList.length">
  
    <div class="pl30 pr30">
      <ul>
        <li v-for="(item, index) in dataList" :key="index" @click="goDet(item)">
          <span class="mark">通知<i v-if="item.read == 0"></i></span>
          <div>
            <h3>{{ item.title }}</h3>
            发布教师：{{ item.add_name }} {{ item.make_time }}
          </div>
        </li>
      </ul>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </div>
  </template>
  <template v-else>
      <div class="noDataWrap">
        <img src="@/assets/images/noData.png" alt="" />
        <p>暂无通知</p>
      </div>
    </template>
  </div>
</template>

<script>
import { notificalist } from "../assets/api";
export default {
  data() {
    return {
      dataList: [],
      page: 1,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goDet(data) {
      // this.$router.push({
      //   path: "/noticeDetail",
      //   query: {
      //     notification_id: data.id,
      //     course_id: this.$route.query.course_id,
      //   },
      // });
      window.open('#/noticeDetail?notification_id='+data.id+'&'+'course_id='+ this.$route.query.course_id)
    },
    getList() {
      let params = {
        page: this.page,
        course_id: this.$route.query.course_id,
        teach_class_id: window.sessionStorage.getItem("teaching_class_id"),
      };
      notificalist(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            this.dataList = this.dataList.concat(res.data);
            console.log(this.dataList);
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
 .noDataWrap {
    text-align: center;
    padding: 100px 113px;
    box-sizing: border-box;
    p {
      margin: 25px 0;
      font-size: 16px;
      color: #999999;
    }
  }
.actbg {
  background: #fff;
  border-radius: 3px;
  height: 100%;
  box-sizing: border-box;
  li {
    height: 75px;
    display: flex;
    padding-top: 16px;
    box-sizing: border-box;
    .mark {
      position: relative;
      font-size: 13px;
      color: #fff;
      border-radius: 4px;
      background: var(--default-color);
      text-align: center;
      width: 42px;
      height: 42px;
      line-height: 42px;
      margin-right: 16px;
      i {
        position: absolute;
        width: 8px;
        height: 8px;
        background: #FFB546;
        border-radius: 50%;
        left: -2px;
        top: -2px;
      }
    }
    div {
      border-bottom: 1px solid #eef1f3;
      font-size: 14px;
      cursor: pointer;
      flex: 1;
      color: #888;
      h3 {
        color: #333;
      }
    }
  }
}
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
  i {
    font-size: 14px;
  }
}
</style>
