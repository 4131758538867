<template>
  <div class="actbg">
    <div class="contentWrap">
      <el-row class="mb10" :gutter="10">
        <el-col :span="6">
          <el-select
            v-model="form.paper_status"
            placeholder="考试进行状态"
            style="width: 100%"
            clearable="true"
          >
            <el-option
              v-for="item in conductList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="form.finish"
            placeholder="完成状态"
            style="width: 100%"
            clearable="true"
          >
            <el-option
              v-for="item in finishList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="getList">搜索</el-button>
          <el-button type="info" class="reset" @click="reset">重置</el-button>
        </el-col>
      </el-row>
      <ul class="examList">
        <li v-for="(item, index) in dataList" :key="index">
          <div class="examListLet">
            <span
              :class="item.answer_id == 0 ? 'nont' : 'already'"
              v-html="item.answer_id == 0 ? '未交卷' : '已交卷'"
            ></span
            ><b>{{ item.title }}</b>
            <p>
              考试时间：{{
                item.start_time | formatimes("yyyy-MM-dd hh:mm")
              }}-{{ item.end_time | formatimes("yyyy-MM-dd hh:mm")
              }}
              <template v-if="item.answer_id!=0">
                <template v-if="item.assess_status==0">
                  <i
                  ><strong>待评分</strong
                  ></i>
                </template>
                <template v-else>
                  <i v-if="item.total_score != -1"
                  ><strong>{{item.total_score+'分' }}</strong
                  ></i>
                </template>
              </template>
            </p>
          </div>
          <div>
            <el-button
              round
              v-if="
                item.paper_status == 0 ||
                (item.paper_status == 1 && item.answer_id == 0)
              "
              @click="turnInto(item)"
              >进入考试</el-button
            >
            <el-button
              round
              v-if="
               item.answer_id > 0 &&
                  item.paper_status == 1 &&
                  item.exams_number != 0
              "
              @click="turnInto(item)"
              >重考</el-button
            >
            <el-button round v-if="item.answer_id > 0" @click="lockReport(item)"
              >查看结果</el-button
            >
          </div>
        </li>
      </ul>
      <div class="studyCoursekdpage" style="margin-top: 30px">
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage41"
          :page-sizes="[20, 30, 40, 50]"
          :page-size="20"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaol"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { newpaperList } from "../assets/api";
export default {
  name: "courseExam",
  data() {
    return {
      form: {
        finish: "",
        paper_status: "",
        page: 1,
        limit: 20,
      },
      conductList: [
        {
          value: "0",
          label: "未开始",
        },
        {
          value: "1",
          label: "进行中",
        },
        {
          value: "2",
          label: "已截止",
        },
      ],
      finishList: [
        {
          value: "1",
          label: "已交卷",
        },
        {
          value: "2",
          label: "未交卷",
        },
      ],
      dataList: [],
      currentPage41: 1,
      totaol: 0,
      nowtime:0,
    };
  },
  mounted() {
    if (this.$route.query.course_id && this.$route.query.cst_id) {
      this.getList();
    }
  },
  methods: {
    turnInto(item) {
      if(item.start_time-this.nowtime<600){
      this.$router.push({
        path: "/examRequire",
        query: {
          course_id: this.$route.query.course_id,
          id: item.id,
          answer_id: item.answer_id,
          paper_id: item.paper_id,
          cst_id: this.$route.query.cst_id,
        },
      });
      }else{
        this.$message.warning("考试未开始");
      }
      if(this.nowtime>item.end_time){
        this.$message.warning("考试已截止");
      }
    },
    lockReport(item) {
      this.$router.push({
        path: "/examReport",
        query: {
          course_id: this.$route.query.course_id,
          id: item.id,
          answer_id: item.answer_id,
          paper_id: item.paper_id,
          cst_id: this.$route.query.cst_id,
          allow_students_answer:(this.nowtime>item.end_time&&item.allow_students_answer==0)||item.allow_students_answer==1?1:0
        },
      });
    },
    reset() {
      this.$data.form = this.$options.data().form;
      this.getList();
    },
    getList() {
      let params = {
        finish: this.form.finish == "" ? 0 : this.form.finish,
        paper_status:
          this.form.paper_status == "" ? -1 : this.form.paper_status,
        course_id: this.$route.query.course_id,
        cst_id: this.$route.query.cst_id,
        title: "",
        page: this.form.page,
        limit: this.form.limit,
      };
      newpaperList(params).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data.list;
          this.totaol = res.data.total;
          this.nowtime = res.data.nowtime
        }
      });
    },
    handleSizeChange1(val) {
      this.form.limit = val;
      this.getList();
    },
    handleCurrentChange1(val) {
      this.form.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.contentWrap {
  padding: 30px;
}
.reset {
  background: #afb8c2;
  border-color: #afb8c2;
}
.examList {
  margin-bottom: 20px;
  li {
    border-bottom: solid 1px #ebeef5;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .examListLet b {
      font-size: 16px;
      line-height: 20px;
    }
    .examListLet span {
      display: inline-block;
      font-size: 12px;
      line-height: 20px;
      padding: 0 8px;
      margin-right: 10px;
      border-radius: 3px;
    }
    .examListLet span.nont {
      background: #fef5ea;
      border: solid 1px #fce4c4;
      color: #f69c20;
    }
    .examListLet span.already {
      background: #ecf5ff;
      border: solid 1px #cae4ff;
      color: #507fff;
    }
    .examListLet p {
      font-size: 14px;
      margin-top: 14px;
      line-height: 20px;
      color: #888;
      i {
        color: #507fff;
        font-style: normal;
        padding-left: 10px;
        margin-left: 10px;
        position: relative;
      }
      i::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background: #ccc;
      }
    }
    button {
      padding: 0;
      color: #507fff;
      border-color: #507fff;
      line-height: 28px;
      width: 90px;
    }
  }
}
.studyCoursekdpage {
  text-align: right;
}
</style>
