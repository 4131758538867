<template>
    <div class="atcSign">
        <h2>
            <i class="iconfont icon-fanhui" @click="gos"></i>教学活动 > 活动管理 > 签到
        </h2>
        <div class="actSignBg pl30 pr30 pt20" v-if="infos.activity_status!=3">
            <ul>
                <li>
                    <span class="mark">签到</span>
                    <div>
                        {{title}}<br>
                        <em>{{str_time | formatimes('yyyy-MM-dd hh:mm')}}</em><span v-if="left_time"><i class="iconfont icon-time"></i>{{left_time}}</span>
                    </div>
                </li>
            </ul>
            <div class="qdcg">
                <img src="../assets/images/qdgq.png" alt="" v-if="infos.activity_status==1"><br><h2>{{infos.activity_status==2?'缺勤':infos.activity_status==4?'早退':infos.activity_status==5?'迟到':infos.activity_status==6?'病假':infos.activity_status==7?'事假':infos.activity_status==8?'公假':''}}</h2>
                {{infos.checkin_time | formatimes('yyyy-MM-dd hh:mm')}}
            </div>
        </div>
        <div class="actSignBg pl30 pr30 pt20" v-if="infos.activity_status==3">
            <ul class="end">
                <li>
                    <span class="mark">签到</span>
                    <div>
                        {{title}}<br>
                        <template v-if="infos.id!=0"><em>{{str_time | formatimes('yyyy-MM-dd hh:mm')}}</em><span>已签到</span></template>
                    </div>
                </li>
            </ul>
            <div class="qdcg">
                <img src="../assets/images/qdgq.png" alt=""><br>
<!--                {{infos.checkin_time | formatimes('yyyy-MM-dd hh:mm')}}-->
            </div>
        </div>
    </div>
</template>

<script>
    import {activityInfo} from "../assets/api";

    export default {
        name: "signIn",
        data(){
            return {
                infos:{},
                title:'',
                left_time:'',
                str_time:''
            }
        },
        methods:{
            gos(){
                this.$router.go(0)
            },
            binsgins(data){
                this.title = data.title
                this.left_time = data.left_time
                this.str_time = data.str_time
                let params = {
                    id:data.id,
                    course_id:data.course_id,
                    cst_id:data.cst_id
                }
                activityInfo(params).then(res=>{
                    if(res.code==0){
                        this.infos = res.data.info
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .atcSign{height: 100%; box-sizing: border-box;
        h2{
            font-weight: normal; font-size: 14px; padding-bottom: 16px;
        i{ margin-right: 10px; color: #787D84; vertical-align: middle;}
        }
        .actSignBg{
            height:calc(100% - 41px); box-sizing: border-box;background: #fff; border-radius: 3px;
            li{
                height: 75px; display: flex; padding-top: 16px; box-sizing: border-box;
                .mark{
                    font-size: 13px; color: #fff; border-radius: 4px; background: #6F7FCF; text-align: center; width: 42px; height: 42px; line-height: 42px; margin-right: 16px;
                }
            div{
                border-bottom:1px solid #EEF1F3; font-size: 14px; cursor: pointer; flex: 1;
                span{ font-size: 13px; color: #FF7D4E;}
                i{font-size: 13px; margin-right: 5px;}
                em{ color: #666666;font-size: 13px; font-style: normal; margin-right: 10px}
            }
        }
        .end li .mark{ background: #E5E5E5; color: #999999;}
        .end li div{
            span{ font-size: 13px; color: #666;}
        }
        .qdcg{
            width: 174px; text-align: center; color: #999999; font-size: 13px; line-height: 30px; position: absolute; left: 50%; top: 50%;
            h2{ font-size: 30px;}
        }
        }
    }
</style>
