<template>
  <div class="actbg">
    <div v-if="showFlag">
		<el-button type="primary" class="rightBtn" @click="openDia"
			>成绩规则</el-button
		>
		<div class="itemWrap">
			<p class="title mb20">总成绩</p>
			<div class="bluePre">
				<div class="blueCenter">
					<img src="../assets/images/achieve_top.png" alt="" />
					<div class="ml15">
						<p>
							<span class="fontsize36 fontBold">{{ statistics.total_score }}</span>
							<span>/100</span>
						</p>
						<p>每N分钟更新一次，如有疑问可联系课程老师</p>
					</div>
				</div>
			</div>
		</div>
		<div class="itemWrap mt30">
			<p class="title mb20">成绩权重</p>
			<div class="perChart">
				<div id="chart" ref="chart" class="chartDiv" />
			</div>
		</div>
		<div class="itemWrap mt30">
			<p class="title mb20">得分进度</p>
			<div class="bottomWrap">
				<div class="bottomItem" v-if="performance.usually_weight">
					<div class="bottomLeft">
						<img src="../assets/images/achieve_ps.png" alt="" />
						<div class="bottomLeftRight ml20">
							<p class="mb10">平时成绩</p>
							<p><span class="fontBold">{{ statistics.usually_score }}</span>/{{ performance.usually_weight }}</p>
						</div>
					</div>
					<el-progress type="circle" :percentage="statistics.usually_score/performance.usually_weight*100" :width="85" :stroke-width="4" text-color="#333" color="#507fff"></el-progress>
				</div>
        <div class="bottomItem" v-if="performance.check_weight">
					<div class="bottomLeft">
						<img src="../assets/images/achieve_kq.png" alt="" />
						<div class="bottomLeftRight ml20">
							<p class="mb10">考勤成绩</p>
							<p><span class="fontBold">{{ statistics.check_score }}</span>/{{ performance.check_weight }}</p>
						</div>
					</div>
					<el-progress type="circle" :percentage="statistics.check_score/performance.check_weight*100" :width="85" :stroke-width="4" text-color="#333" color="#507fff"></el-progress>
				</div>
        <div class="bottomItem" v-if="performance.job_weight">
					<div class="bottomLeft">
						<img src="../assets/images/achieve_zy.png" alt="" />
						<div class="bottomLeftRight ml20">
							<p class="mb10">作业成绩</p>
							<p><span class="fontBold">{{ statistics.job_score }}</span>/{{ performance.job_weight }}</p>
						</div>
					</div>
					<el-progress type="circle" :percentage="statistics.job_score/performance.job_weight*100" :width="85" :stroke-width="4" text-color="#333" color="#507fff"></el-progress>
				</div>
        <div class="bottomItem" v-if="performance.exam_weight">
					<div class="bottomLeft">
						<img src="../assets/images/achieve_ks.png" alt="" />
						<div class="bottomLeftRight ml20">
							<p class="mb10">考试成绩</p>
							<p><span class="fontBold">{{ statistics.exam_score }}</span>/{{ performance.exam_weight }}</p>
						</div>
					</div>
					<el-progress type="circle" :percentage="statistics.exam_score/performance.exam_weight*100" :width="85" :stroke-width="4" text-color="#333" color="#507fff"></el-progress>
				</div>
			</div>
		</div>
		<RuleDia ref="RuleDia"></RuleDia>
	  </div>
    <div  v-else class="noData_ach">
      <img src="../assets/images/noData_ach.png" alt="">
    </div>
  </div>
	
</template>

<script>
import RuleDia from './courseAchievement/ruleDia.vue';
import {achievementInfo} from '../assets/api'
export default {
	components: { RuleDia },
	data() {
		return {
      statistics:{},
      performance:{},
      ually_weight:{},
      check_weight:{},
      showFlag:false,
      chartData:[]
    };
	},
	mounted() {
    this.getData()
		
	},
	methods: {
    getData(){
      let params={
        course_id	:this.$route.query.course_id,
        cst_id:this.$route.query.cst_id
      }
      achievementInfo(params).then(res=>{
        if(res.code==0){
          this.showFlag=true
          //上
          this.performance =res.data.performance 
          //下
          this.statistics =res.data.statistics 
          //弹窗
          this.ually_weight=res.data.ually_weight
          this.check_weight=res.data.check_weight
          this.chartData=[]
          if(this.performance.exam_weight){
            this.chartData.push({ value: this.performance.exam_weight, name: '考试权重' })
          }
          if(this.performance.usually_weight){
            this.chartData.push({ value: this.performance.usually_weight, name: '平时权重' })
          }
          if(this.performance.job_weight){
            this.chartData.push({ value: this.performance.job_weight, name: '作业权重' })
          }
          if(this.performance.check_weight){
            this.chartData.push({ value: this.performance.check_weight, name: '考勤权重' })
          }
          this.$nextTick(()=>{
            this.myChart();
          })
         
        }else{
          if(res.message=='成绩尚未配置'){
            this.showFlag=false
          }
        }
      })
    },
		openDia() {
			this.$refs.RuleDia.openInit(this.performance,this.statistics,this.ually_weight,this.check_weight);
		},
		myChart() {
			var myChart = this.$echarts.init(this.$refs.chart);
			var option;
			option = {
				title: {
					text: '',
					left: 'center',
					textStyle: {
						color: '#333',
						fontWeight: 'normal',
						fontSize: 20,
						lineheight: 26,
					},
					top: '45%',
				},
				legend: {
					top: 'bottom',
					itemWidth: 16,
					itemHeight: 16,
					icon: 'roundRect',
					selectedMode: false,
				},
				color: ['#7C8DFF', '#04E38A', '#3651FF', '#FFE689'],
				series: [
					{
						type: 'pie',
						radius: ['30%', '55%'],
						center: ['50%', '50%'],
						avoidLabelOverlap: false,
						label: {
							show: true,
							formatter(param) {
								return param.name + param.value + '%';
							},
						},
						data: [...this.chartData],
					},
				],
				labelLine: {
					show: true,
					length: 48,
					length2: 0,
					lineStyle: {
						width: 1,
						type: 'solid',
					},
				},
			};
			option && myChart.setOption(option);
		},
	},
};
</script>

<style scoped lang="scss">
.actbg {
	background: #fff;
	border-radius: 3px;
	height: 100%;
	box-sizing: border-box;
	padding: 30px 30px 0px 30px;
	box-sizing: border-box;
	position: relative;
	.rightBtn {
		position: absolute;
		right: 30px;
		top: 20px;
	}
	.itemWrap {
		.title {
			font-size: 16px;
		}
		.bluePre {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #01c2d4;
			border-radius: 4px;
			height: 120px;
			color: #fff;
			font-size: 14px;
			.blueCenter {
				display: flex;
				align-items: center;
				.ml15 {
					margin-left: 15px;
				}
				.fontsize36 {
					font-size: 36px;
				}
				.fontBold {
					font-weight: bold;
				}
			}
		}
	}
	.perChart {
		padding: 0 100px 20px;
		border: 1px solid #ebeef5;
		border-radius: 3px;
		display: flex;
		.chartDiv {
			flex: 1;
			height: 400px;
			width: 600px;
		}
	}
	.bottomWrap {
		display: flex;
    flex-wrap: wrap;
		.bottomItem {
			// flex: 1;
      width: 378px;
			height: 140px;
			border: 1px solid #e6e6e6;
      border-radius: 6px;
			display: flex;
			margin-right: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 30px;
      box-sizing: border-box;
      margin-bottom:20px;
			&:last-child {
				margin-right: 0;
			}
			.bottomLeft {
				display: flex;
				align-items: center;
				.bottomLeftRight {
					font-size: 14px;
				}
			}
		}
	}
}
.noData_ach{
  text-align: center;
  padding-top:200px;
  box-sizing: border-box;
}
</style>
