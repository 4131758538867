<template>
	<div class="">
		<el-dialog
			:visible.sync="dialogVisible"
			width="600px"
			custom-class="diag"
			title="温馨提示"
		>
			<div class="contentWrap">
				<h3 class="mb10">
					{{ tipData.title }}
				</h3>
				<div class="tipInfo mb10">
					<span v-if="tipData.time_limit"
						>截止时间：{{
							tipData.time_limit | formatimes('yyyy-MM-dd hh:mm')
						}}</span
					>
					<span v-else>无限时</span>
					<span class="ml10 mr10">|</span>
					<span class="colorRed" v-if="tipData.allow_late == 1"
						><i class="iconfont icon-jinzhi"></i>禁止迟交</span
					>
					<span v-else>允许迟交</span>
					<span class="ml10 mr10">|</span>
					<span class="colorRed" v-if="tipData.allow_reply == 0"
						><i class="iconfont icon-jinzhi"></i>禁止重复提交</span
					>
					<span v-else>允许重复提交{{ tipData.allow_reply }}次</span>
				</div>
				<div class="tipInfo">
					布置教师：{{ tipData.add_username }}，{{
						tipData.add_time | formatimes('yyyy-MM-dd hh:mm')
					}}
				</div>
				<div class="tipContent" v-if="tipData.notice">
					作业要求：{{ tipData.notice }}
				</div>
				<div class="bottomWrap">

					<el-button
						type="primary"
						class="newbtn"
						v-if="
							tipData.allow_reply == 1 ||
							(tipData.answer_status == 2 &&
								tipData.time_limit == 0) ||
							(tipData.answer_status == 2 &&
              listData.assignment_status == 1)
						"
            @click="jumpHomework(listData)"
						>重新作答</el-button
					>
          <el-button
						type="primary"
						class="newbtn"
            v-if="(tipData.answer_id == 0&&listData.assignment_status!=2)||(tipData.answer_id == 0&&listData.assignment_status==2&&tipData.allow_late==0)"
             @click="jumpHomework(listData)"
						>开始作答</el-button
					>
					<el-button
						type="primary"
						class="newbtn"
						v-if="
							(tipData.answer_status != 2 &&
								tipData.answer_id > 0) ||
							(tipData.answer_status == 2 &&
								tipData.time_limit &&
								listData.assignment_status != 1 &&
								tipData.answer_id > 0)
						"
            @click="lockReport(listData)"
						>查看作业</el-button
					>
					<template
						v-if="
							tipData.answer_id == 0 &&
							listData.assignment_status == 2&&tipData.allow_late
						"
					>
						<img src="../../assets/images/tipImg.png" alt="" />
						<span class="overWrap"
							>作业提交时间已过，您的作业未提交</span
						>
					</template>
				</div>
				<div class="tipTag">
					<img
						src="../../assets/images/tipbdh.png"
						alt=""
						v-if="tipData.answer_status == 2"
					/>
					<img src="../../assets/images/tipycj.png" alt="" v-if="listData.assignment_status==2&&listData.allow_late==0" /> 
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { assignmentmain } from '../../assets/api';

export default {
	data() {
		return {
			dialogVisible: false,
			tipData: {},
      listData:{}
		};
	},
	mounted() {},
	methods: {
		openInit(data) {
			this.tipData = {};
			this.dialogVisible = true;
			this.listData = data;
			this.course_assignment_id = data.id;
			this.course_id = this.$route.query.course_id;
			this.cst_id = this.$route.query.cst_id;
			this.getTips();
		},
		getTips() {
			let params = {
				course_assignment_id: this.course_assignment_id,
				course_id: this.course_id,
				cst_id: this.cst_id,
			};
			assignmentmain(params).then((res) => {
				if (res.code == 0) {
					this.tipData = res.data;
				}
			});
		},
    lockReport(item) {
      this.dialogVisible = false;
      console.log(item)
      if (item.iswork == 0) {
        this.$router.push({
          path: "/homework",
          query: {
            assignment_id: item.relation_id,
            id: item.id,
            course_id: item.course_id,
            cst_id: item.cst_id,
            answer_id: item.answer_id,
            newHomework: 1,
            isAnswer: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/examReport",
          query: {
            course_id: this.$route.query.course_id,
            id: item.id,
            answer_id: item.answer_id,
            paper_id: item.relation_id,
            cst_id: this.$route.query.cst_id,
            isExam: 1,
          },
        });
      }
    },
    jumpHomework(data) {
      this.dialogVisible = false;
      if (data.iswork == 0) {
        this.$router.push({
          path: "/homework",
          query: {
            assignment_id: data.relation_id,
            id: data.id,
            course_id: data.course_id,
            cst_id: data.cst_id,
            answer_id: data.answer_id,
            newHomework: 2,
            isAnswer: 2,
          },
        });
      } else {
        this.$router.push({
          path: "/studentsExam",
          query: {
            course_id: data.course_id,
            id: data.id,
            answer_id: data.answer_id,
            paper_id: data.relation_id,
            cst_id: this.$route.query.cst_id,
            isExam: 1,
          },
        });
      }
    },
	},
};
</script>

<style scoped lang="scss">
/deep/.diag {
	.el-dialog__title {
		line-height: 24px;
		font-size: 13px;
		color: #333;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eee;
		padding: 15px 20px;
		.el-dialog__headerbtn {
			top: 17px;
		}
	}
	.el-dialog__body {
		padding: 30px 40px 20px 30px;
		color: #333;
		font-size: 14px;
		.contentWrap {
			position: relative;
			h3 {
				font-size: 16px;
			}
			.tipInfo {
				color: #888;
				font-size: 13px;
				.colorRed {
					color: #f55a5a;
				}
				i {
					font-size: 12px;
					margin-right: 5px;
				}
			}
			.tipContent {
				line-height: 25px;
				padding-top: 20px;
			}
			.bottomWrap {
				text-align: center;
				padding-top: 30px;
				.newbtn {
					height: 34px;
					margin-bottom: 10px;
				}
				img {
					vertical-align: top;
				}
				.overWrap {
					color: #aaa;
					font-size: 20px;
					margin-left: 5px;
					line-height: 20px;
				}
			}
			.tipTag {
				position: absolute;
				right: 0;
				bottom: 20px;
				img {
					width: 100px;
					height: 100px;
				}
			}
		}
	}
}
</style>
