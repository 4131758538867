<template>
  <div class="actbg" v-if="sign">
    <h2>{{ teaching_class.title }}</h2>
    <div class="pl30 pr30">
      <h3>进行中（{{ in_progress_list.length }}个）</h3>
      <ul>
        <li
          @click="binsgin(item)"
          v-for="(item, index) in in_progress_list"
          :key="index"
        >
          <span class="mark" v-if="item.activity_type == 1">签到</span>
          <span class="marks" v-if="item.activity_type == 2"
            >随堂<br />测验</span
          >
          <span class="mark" v-if="item.activity_type == 3">选人</span>
          <span class="mark" v-if="item.activity_type == 4">抢答</span>
          <div>
            <strong :class="item.is_check_status == 0?'fr':'fr colorGreen'" v-if="item.activity_type == 1">{{item.is_check_status == 0?'点击签到':'已签到'}}</strong>
            <strong
              class="fr"
              v-if="item.activity_type == 2 && item.is_check_status == 0"
              >开始答题</strong
            >
            <strong
              class="fr"
              v-if="item.activity_type == 2 && item.is_check_status == 1"
              >查看作答</strong
            >
            <strong
              class="fr"
              v-if="item.activity_type == 3 || item.activity_type == 4"
              >查看</strong
            >
            {{ item.title }}<br />
            <span v-if="item.left_time"
              ><i class="iconfont icon-time"></i>{{ item.left_time }}</span
            >
          </div>
        </li>
      </ul>
      <h3>已结束（{{ finished_list.length }}个）</h3>
      <ul class="end">
        <li v-for="(item, index) in finished_list" :key="index" @click="binsgin(item)">
          <span class="mark" v-if="item.activity_type == 1">签到</span>
          <span class="marks" v-if="item.activity_type == 2"
            >随堂<br />测验</span
          >
          <span class="mark" v-if="item.activity_type == 3">选人</span>
          <span class="mark" v-if="item.activity_type == 4">抢答</span>
          <div>
            <strong class="fr">{{
              item.str_time | formatimes("yyyy-MM-dd hh:mm")
            }}</strong>
            {{ item.title }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="atcSign">
    <signIn ref="signIn" v-if="activity_type == 1"></signIn>
    <classQuiz
      ref="classQuiz"
      v-if="activity_type == 2 && activity_type2_status == 0"
    ></classQuiz>
    <classQuizReport
      ref="classQuizReport"
      v-if="activity_type == 2 && activity_type2_status == 1"
    ></classQuizReport>
    <choosePeople ref="choosePeople" v-if="activity_type == 3"></choosePeople>
    <rushAnswer ref="rushAnswer" v-if="activity_type == 4"></rushAnswer>
  </div>
</template>

<script>
import { activityList } from "../assets/api";
import signIn from "./signIn";
import classQuiz from "./classQuiz";
import classQuizReport from "./classQuiz_report";
import choosePeople from "./choosePeople.vue";
import rushAnswer from "./rushAnswer.vue";
export default {
  components: { signIn, classQuiz, classQuizReport, choosePeople, rushAnswer },
  data() {
    return {
      sign: true, //false,
      atcsign: true,
      finished_list: [],
      in_progress_list: [],
      teaching_class: {},
      infos: {},
      title: "",
      left_time: "",
      str_time: "",
      activity_type: 1,
      activity_type2_status: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    binsgin(data) {
      this.sign = false;
      this.activity_type = data.activity_type;
      if (data.activity_type == 1) {
        this.$nextTick(() => {
          this.$refs.signIn.binsgins(data);
        });
      }
      if (data.activity_type == 2) {
        this.activity_type2_status = data.is_check_status;
        if (data.is_check_status == 0) {
          this.$nextTick(() => {
            this.$refs.classQuiz.title = data.title;
            this.$refs.classQuiz.getInfo(data.id);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.classQuizReport.title = data.title;
            this.$refs.classQuizReport.str_time = data.str_time;
            this.$refs.classQuizReport.type = 2;
            this.$refs.classQuizReport.getpaperList(data.id);
          });
        }
      }
    },
    getList() {
      let params = {
        course_id: this.$route.query.course_id,
        cst_id: this.$route.query.cst_id,
      };
      activityList(params).then((res) => {
        if (res.code == 0) {
          this.finished_list = res.data.finished_list;
          this.in_progress_list = res.data.in_progress_list;
          this.teaching_class = res.data.teaching_class;
          window.sessionStorage.setItem(
            "teaching_class_id",
            this.teaching_class.id
          );
          this.$parent.is_settings=res.data.teaching_class.is_settings

        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.actbg {
  background: #fff;
  border-radius: 3px;
  height: 100%;
  box-sizing: border-box;
  h2 {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #eef1f3;
    font-size: 16px;
    font-weight: normal;
    padding-left: 30px;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    height: 21px;
    padding: 20px 0 10px 0;
  }
  li {
    height: 75px;
    display: flex;
    padding-top: 16px;
    box-sizing: border-box;
    .mark {
      font-size: 13px;
      color: #fff;
      border-radius: 4px;
      background: #6F7FCF;
      text-align: center;
      width: 42px;
      height: 42px;
      line-height: 42px;
      margin-right: 16px;
    }
    .marks {
      font-size: 13px;
      color: #fff;
      border-radius: 4px;
      background: #00c292;
      text-align: center;
      width: 42px;
      height: 42px;
      line-height: 16px;
      margin-right: 16px;
      box-sizing: border-box;
      padding-top: 5px;
    }
    div {
      border-bottom: 1px solid #eef1f3;
      font-size: 14px;
      cursor: pointer;
      flex: 1;
      span {
        font-size: 13px;
        color: #ff7d4e;
      }
      i {
        font-size: 13px;
        margin-right: 5px;
      }
      strong {
        font-size: 13px;
        font-weight: normal;
        color: #507fff;
        margin-top: 15px;
        &.colorGreen{
          color: #0DAF0D;
        }
      }
      .green {
        color: #67c23a;
      }
    }
  }
  .end li .mark {
    background: #e5e5e5;
    color: #999999;
  }
  .end li .marks {
    background: #e5e5e5;
    color: #999999;
  }
  .end li div {
    line-height: 40px;
    strong {
      color: #333;
      margin-top: 0px;
    }
  }
}
.atcSign {
  height: 100%;
  box-sizing: border-box;
  h2 {
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 16px;
    i {
      margin-right: 10px;
      color: #787d84;
      vertical-align: middle;
    }
  }
  .actSignBg {
    height: calc(100% - 41px);
    box-sizing: border-box;
    background: #fff;
    border-radius: 3px;
    li {
      height: 75px;
      display: flex;
      padding-top: 16px;
      box-sizing: border-box;
      .mark {
        font-size: 13px;
        color: #fff;
        border-radius: 4px;
        background: #507fff;
        text-align: center;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-right: 16px;
      }
      div {
        border-bottom: 1px solid #eef1f3;
        font-size: 14px;
        cursor: pointer;
        flex: 1;
        span {
          font-size: 13px;
          color: #ff7d4e;
        }
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        em {
          color: #666666;
          font-size: 13px;
          font-style: normal;
          margin-right: 10px;
        }
      }
    }
    .end li .mark {
      background: #e5e5e5;
      color: #999999;
    }
    .end li div {
      span {
        font-size: 13px;
        color: #666;
      }
    }
    .qdcg {
      width: 174px;
      text-align: center;
      color: #999999;
      font-size: 13px;
      line-height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      h2 {
        font-size: 30px;
      }
    }
  }
}
</style>
