<template>
	<!-- 论述 -->
	<div class="discussWrap" :id="'question' + list.question_seq">
		<b class="itemNum">{{ list.question_seq }} <span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
		<div class="itemDetail">
			<div class="detailTitle">
				<span class="detailCate">论述题</span>
				<div class="detailContent" v-html="list.title"></div>
			</div>
			<div class="detailOption">
				<div class="optionWrap" v-if="!list.subFlag">
					<el-input
						type="textarea"
						:rows="8"
						placeholder="请输入内容"
						v-model="list.radio"
						class="mb20"
					></el-input>
					<el-upload
						:data="upld"
						action="https://jsonplaceholder.typicode.com/posts/"
						list-type="picture-card"
						:limit="9"
						:file-list="list.stu_img"
						:on-preview="handlePictureCardPreview"
						:on-remove="handleRemove"
						:on-success="sucesspic"
					>
						<i class="el-icon-plus"></i>
						<br />
						<span>添加图片</span>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="" />
					</el-dialog>
				</div>
				<span class="queding" @click="sub()" v-if="!list.subFlag"
					>确定</span
				>
			</div>
			<Point :thought_dial="list" :is_check_asw="is_check_asw"></Point>
		</div>
		<i></i>
	</div>
</template>

<script>
import Point from './com/Point.vue';
export default {
	props: {
		list: Object,
		is_stuff: Number,
		is_check_asw: Boolean,
	},
	components: {
		Point,
	},
	data() {
		return {
			dialogImageUrl: '',
			dialogVisible: false,
			upld: {
				exam_id: this.$store.state.exam_id,
				area_id: this.$store.state.area_id,
			},
		};
	},
	mounted() {
		this.sub();
	},
	methods: {
		collect(type) {
			let params = {
				exam_id: this.$store.state.exam_id,
				area_id: this.$store.state.area_id,
				paper_question_id: this.list.id,
				pqtype: 1,
				status: type == 0 ? 1 : 2,
			};
			updatecollect(params).then((res) => {
				if (res.flag == 1) {
					if (this.list.iscollect == 0) {
						this.list.iscollect = 1;
					} else {
						this.list.iscollect = 0;
					}
				}
			});
		},
		//上传图片
		sucesspic(file) {
			this.$set(this.list, 'stu_img', []);
			this.list.stu_img.push({ url: file.ossurl });
		},
		handleRemove(file, fileList) {
			this.list.stu_img.forEach((item, index) => {
				if (file.url == item.url) {
					this.list.stu_img.splice(index, 1);
				}
			});
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		sub() {
			this.$set(this.list, 'subFlag', true);
		},
	},
};
</script>

<style scoped lang=""></style>
