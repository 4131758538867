<template>
	<div class="">
		<el-dialog
			:visible.sync="dialogVisible"
			width="800px"
			custom-class="diag"
			title="成绩规则"
		>
			<div>
				<el-timeline class="timelineCss">
					<el-timeline-item>
						<h4>总成绩</h4>
						<p>
							总成绩(100分) = 平时成绩({{
								performance.usually_weight
							}}分)+考勤成绩({{
								performance.check_weight
							}}分)+作业成绩({{
								performance.job_weight
							}}分)+考试成绩({{ performance.exam_weight }}分)。
						</p>
					</el-timeline-item>
					<el-timeline-item v-if="performance.usually_weight">
						<h4>平时成绩({{ performance.usually_weight }}分)</h4>
						<p>
							平时成绩 = 学习进度加权成绩({{ually_weight.learning_weight}}%) +
							互动表现加权成绩({{ually_weight.interaction_weight}}%)。
						</p>
						<p>
							必修课件的学习进度达到{{ually_weight.learning_progress}}%开始计算得分，否则记为0分。
						</p>
						<p>
							互动表现包括随堂测验、抢答、选人、投票。学习进度分=课件学习进度%
							* 平时成绩总分 * 学习进度权重%
						</p>
					</el-timeline-item>
					<el-timeline-item v-if="performance.check_weight">
						<h4>考勤成绩({{ performance.check_weight }}分)</h4>
						<p>
							每次签到得{{check_weight.sign_in_rate}}分，每次缺勤不得分、每次过期签到得{{check_weight.expires}}分、每次事假得{{check_weight.event}}分、每次病假得{{check_weight.sick}}分、每次公假得{{check_weight.public}}分、每次迟到得{{check_weight.late}}分、每次早退得{{check_weight.early}}分。
						</p>
					</el-timeline-item>
					<el-timeline-item v-if="performance.job_weight">
						<h4>作业成绩({{ performance.job_weight }}分)</h4>
						<p>
							作业成绩 = 作业平均(加权)成绩 x 权重{{
								performance.job_weight
							}}%（未交、未批的作业均算为0分）。
						</p>
					</el-timeline-item>
					<el-timeline-item v-if="performance.exam_weight">
						<h4>考试成绩({{ performance.exam_weight }}分)</h4>
						<p>
							考试成绩 = 考试平均(加权)成绩 x 权重{{
								performance.exam_weight
							}}%（未交、未批的考试均算为0分）。
						</p>
					</el-timeline-item>
				</el-timeline>
			</div>
			<div class="btnWrap">
				<el-button type="info" @click="dialogVisible = false"
					>取消</el-button
				>
				<el-button type="primary" @click="dialogVisible = false"
					>确定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			statistics: {},
			performance: {},
			ually_weight: {},
			check_weight: {},
		};
	},
	mounted() {},
	methods: {
		openInit(performance, statistics, ually_weight, check_weight) {
			this.performance = performance;
			this.statistics = statistics;
			this.ually_weight = ually_weight;
			this.check_weight = check_weight;
			this.dialogVisible = true;
		},
	},
};
</script>

<style scoped lang="scss">
/deep/.diag {
	.el-dialog__title {
		line-height: 24px;
		font-size: 13px;
		color: #333;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eee;
		padding: 15px 20px;
	}
	.el-dialog__body {
		padding: 30px 40px;
		color: #555;
	}
	.timelineCss {
		h4 {
			margin-bottom: 10px;
		}
		p {
			line-height: 24px;
		}
	}

	.btnWrap {
		text-align: center;
		.el-button--info {
			background: #eeeeee;
			border: 1px solid #eeeeee;
			color: #666;
			&:hover,
			&:focus {
				background: #ddd;
			}
		}
	}
}
</style>
