var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"600px","custom-class":"diag","title":"温馨提示"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"contentWrap"},[_c('h3',{staticClass:"mb10"},[_vm._v(" "+_vm._s(_vm.tipData.title)+" ")]),_c('div',{staticClass:"tipInfo mb10"},[(_vm.tipData.time_limit)?_c('span',[_vm._v("截止时间："+_vm._s(_vm._f("formatimes")(_vm.tipData.time_limit,'yyyy-MM-dd hh:mm')))]):_c('span',[_vm._v("无限时")]),_c('span',{staticClass:"ml10 mr10"},[_vm._v("|")]),(_vm.tipData.allow_late == 1)?_c('span',{staticClass:"colorRed"},[_c('i',{staticClass:"iconfont icon-jinzhi"}),_vm._v("禁止迟交")]):_c('span',[_vm._v("允许迟交")]),_c('span',{staticClass:"ml10 mr10"},[_vm._v("|")]),(_vm.tipData.allow_reply == 0)?_c('span',{staticClass:"colorRed"},[_c('i',{staticClass:"iconfont icon-jinzhi"}),_vm._v("禁止重复提交")]):_c('span',[_vm._v("允许重复提交"+_vm._s(_vm.tipData.allow_reply)+"次")])]),_c('div',{staticClass:"tipInfo"},[_vm._v(" 布置教师："+_vm._s(_vm.tipData.add_username)+"，"+_vm._s(_vm._f("formatimes")(_vm.tipData.add_time,'yyyy-MM-dd hh:mm'))+" ")]),(_vm.tipData.notice)?_c('div',{staticClass:"tipContent"},[_vm._v(" 作业要求："+_vm._s(_vm.tipData.notice)+" ")]):_vm._e(),_c('div',{staticClass:"bottomWrap"},[(
							_vm.tipData.allow_reply == 1 ||
							(_vm.tipData.answer_status == 2 &&
								_vm.tipData.time_limit == 0) ||
							(_vm.tipData.answer_status == 2 &&
              _vm.listData.assignment_status == 1)
						)?_c('el-button',{staticClass:"newbtn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.jumpHomework(_vm.listData)}}},[_vm._v("重新作答")]):_vm._e(),((_vm.tipData.answer_id == 0&&_vm.listData.assignment_status!=2)||(_vm.tipData.answer_id == 0&&_vm.listData.assignment_status==2&&_vm.tipData.allow_late==0))?_c('el-button',{staticClass:"newbtn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.jumpHomework(_vm.listData)}}},[_vm._v("开始作答")]):_vm._e(),(
							(_vm.tipData.answer_status != 2 &&
								_vm.tipData.answer_id > 0) ||
							(_vm.tipData.answer_status == 2 &&
								_vm.tipData.time_limit &&
								_vm.listData.assignment_status != 1 &&
								_vm.tipData.answer_id > 0)
						)?_c('el-button',{staticClass:"newbtn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.lockReport(_vm.listData)}}},[_vm._v("查看作业")]):_vm._e(),(
							_vm.tipData.answer_id == 0 &&
							_vm.listData.assignment_status == 2&&_vm.tipData.allow_late
						)?[_c('img',{attrs:{"src":require("../../assets/images/tipImg.png"),"alt":""}}),_c('span',{staticClass:"overWrap"},[_vm._v("作业提交时间已过，您的作业未提交")])]:_vm._e()],2),_c('div',{staticClass:"tipTag"},[(_vm.tipData.answer_status == 2)?_c('img',{attrs:{"src":require("../../assets/images/tipbdh.png"),"alt":""}}):_vm._e(),(_vm.listData.assignment_status==2&&_vm.listData.allow_late==0)?_c('img',{attrs:{"src":require("../../assets/images/tipycj.png"),"alt":""}}):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }