<template>
  <div style="height: 100%">
    <Header :bgColor="bgColor"></Header>
    <div
            v-if="course.in_list > 0 && course.is_expired == 0"
            class="courseinfoWrap"
    >
      <div class="course-info-wrapper" style="height: 100%">
        <div class="leftWrap">
          <div class="courseinfoTitle">
            <div class="imgWrap">
              <img v-if="course.image" :src="course.image" />
              <img
                      v-else
                      :src="require('../../../assets/images/nocourse.png')"
              />
              <span class="godoor" @click="godoor">去门户</span>
            </div>
            <div class="titleWrap">
              {{ course.title }}
            </div>
          </div>
          <div class="course-info-menu">
            <template v-for="item of pageMenus">
              <a
                      :key="item.name"
                      :class="{ 'active-page-menu': item.id == activeMenuPath }"
                      @click="save(item.id)"
                      v-if="item.id != 8&&item.value"
              >
                <i :class="['iconfont', item.icon]" />
                <span
                >{{ item.name
                  }}<em v-if="item.id == 6 && num" class="marks">{{
                    num > 99 ? "99+" : num
                  }}</em></span
                >
              </a>
              <!-- v-if="item.id==8&&is_settings==1" -->
              <a
                      :key="item.name"
                      :class="{ 'active-page-menu': item.id == activeMenuPath }"
                      @click="save(item.id)"
                      v-if="item.id == 8 && is_settings == 1 &&item.value"
              >
                <i :class="['iconfont', item.icon]" />
                <span
                >{{ item.name
                  }}<em v-if="item.id == 6 && num" class="marks">{{
                    num > 99 ? "99+" : num
                  }}</em></span
                >
              </a>
            </template>
            <!-- <a
              v-for="item of pageMenus"
              :key="item.name"
              :class="{
                'active-page-menu': item.id === activeMenuPath,
              }"
              @click="activeMenuPath = item.id"
            >
              <i :class="['iconfont', item.icon]" />
              <span
                >{{ item.name
                }}<em v-if="item.id == 6&& num" class="marks">{{
                  num > 99 ? "99+" : num
                }}</em></span
              >
            </a> -->
          </div>
        </div>
        <div class="rightWrap">
          <courseAct v-if="activeMenuPath == 0"></courseAct>
          <courseNotice v-if="activeMenuPath == 6"></courseNotice>
          <courseHomework v-if="activeMenuPath == 7"></courseHomework>
          <CourseAchievement v-if="activeMenuPath == 8"></CourseAchievement>
          <courseExam v-if="activeMenuPath == 9"></courseExam>
          <div class="actbg" v-if="activeMenuPath == 3">
            <CourseData
                    ref="CourseData"
                    :ssid="course.sou_school_id"
            ></CourseData>
          </div>
          <div class="actbg" v-if="activeMenuPath == 4">
            <CourseNote ref="CourseNote"></CourseNote>
          </div>
          <div class="actbg" v-if="activeMenuPath == 5">
            <CourseEval
                    ref="CourseEval"
                    :is_expired="course.is_expired"
                    :appraisal_star="course.appraisal_star"
            ></CourseEval>
          </div>
          <div class="actbg" v-if="activeMenuPath == 2">
            <CoursePlan
                    ref="CoursePlan"
                    v-if="activeMenuPath == 2"
                    :modelFlag="course.is_stage"
                    :expired="course.is_expired"
            ></CoursePlan>
          </div>
        </div>
      </div>
    </div>
    <nolearn ref="nolearn" v-else :in_list="course.in_list"></nolearn>
  </div>
</template>

<script>
  import courseExam from "./components/courseExam";
  import CourseAchievement from "./components/courseAchievement.vue";
  import Header from "@p/video/components/header";
  import courseAct from "./components/courseAct";
  import courseNotice from "./components/courseNotice";
  import courseHomework from "./components/courseHomework";
  import CourseData from "@p/learn/components/courseData.vue";
  import CourseNote from "@p/learn/components/courseNote.vue";
  import CourseEval from "@p/learn/components/courseEval.vue";
  import CoursePlan from "@p/learn/components/coursePlan.vue";
  import nolearn from "./components/nolearn";
  import { courseInfo } from "../learn/assets/api";
  import { notifilistnum } from "./assets/api";
  export default {
    components: {
      nolearn,
      Header,
      courseAct,
      courseNotice,
      courseHomework,
      courseExam,
      CourseData,
      CourseNote,
      CourseEval,
      CoursePlan,
      CourseAchievement,
    },
    data() {
      return {
        bgColor: true,
        activeMenuPath: 0,
        pageMenus: [
          {
            id: 2,
            name: "课件安排",
            icon: "icon-kjap",
            path: 'lesson'
          },
          {
            icon: "icon-uxbjxhd",
            name: "教学活动",
            id: 0,
            path: 'activity'
          },
          {
            icon: "icon-icon_wzzjk",
            name: "通知",
            id: 6,
            path: 'notification'
          },
          {
            icon: "icon-zuoye",
            name: "作业",
            id: 7,
            path: 'assignment'
          },
          {
            icon: "icon-kcbj",
            name: "考试",
            id: 9,
            path: 'examination'
          },
          // {
          //   id: 1,
          //   name: "课程介绍",
          //   icon: "icon-kcjs",
          // },

          {
            id: 3,
            name: "课后资料",
            icon: "icon-khzl",
            path: 'after_school'
          },
          {
            id: 4,
            name: "课程笔记",
            icon: "icon-kcbj",
            path: 'note'
          },
          {
            id: 5,
            name: "课程评价",
            icon: "icon-kcpj1",
            path: 'appraise'
          },
          {
            id: 8,
            name: "成绩管理",
            icon: "icon-kcpj1",
            path: 'performance'
          },
        ],
        num: 0,
        course: {},
        is_settings: "",
      };
    },
    // beforeRouteEnter(to,from,next) {
    //   if(from.path=='/examRequire'){
    //
    //     next(vm=>{
    //       vm.activeMenuPath = 9
    //     })
    //   }
    //   next()
    // },
    mounted() {
      if(sessionStorage.getItem('activeMenuPath')){
        this.activeMenuPath = sessionStorage.getItem('activeMenuPath')
      }
      this.getcourseInfo();
    },
    methods: {
      save(val){
        this.activeMenuPath = val
        sessionStorage.setItem('activeMenuPath',val)
      },
      godoor() {
        this.$router.push({
          path: "/learn",
          query: { course_id: this.$route.query.course_id },
        });
      },
      getcourseInfo() {
        let params = {
          course_id: this.$route.query.course_id,
        };
        courseInfo(params).then((res) => {
          if (res.code == 0) {
            this.course = res.data;
            Object.keys(res.data.settings).forEach(key => {
              this.pageMenus.forEach(item=>{
                if(((item.path && item.path == key) && res.data.settings[key] ==1) || !item.path){
                  this.$set(item, 'value', 1)
                } else if((item.path && item.path == key) && res.data.settings[key] ==0){
                  this.$set(item, 'value', 0)
                }
              })
            })
            try{
              this.pageMenus.map(item=>{
                if(item.value){
                  this.activeMenuPath = item.id
                  throw new Error()
                }
              })
            }catch (e) {

            }
            this.$nextTick(() => {
              this.$addStorageEvent(2, "course", JSON.stringify(res.data));
              this.getnotNum();
              if (res.data.in_list == 0 || res.data.is_expired != 0) {
                this.$refs.nolearn.times();
              }
            });
          } else if (res.message == "课程不存在。") {
            this.$refs.nolearn.times();
          }
        });
      },
      getnotNum() {
        let params = {
          teach_class_id: this.$route.query.cst_id,
        };
        notifilistnum(params).then((res) => {
          if (res.code == 0) {
            this.num = res.data.num;
          }
        });
      },
    },
    destroyed() {
      // sessionStorage.removeItem('activeMenuPath')
    },
    beforeRouteLeave(to,from,next){
      if(to.path=='/examRequire'||to.path=='/examReport'){

      }else {
        sessionStorage.removeItem('activeMenuPath')
      }
      next()
    }
  };
</script>
<style scoped lang="scss">
  .actbg {
    background: #fff;
    border-radius: 3px;
    height: 100%;
    box-sizing: border-box;
  }
  .courseinfoWrap {
    padding-top: 73px;
    box-sizing: border-box;
    height: 100%;
  }
  .courseinfo-page {
    display: flex;
    margin-top: 19px;
    height: 301px;
    .img-wrapper {
      width: 465px;
      height: 261px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .info-wrapper {
      margin: 12px 0 0 55px;
      .title {
        font-size: 26px;
        color: #333333;
      }
      .valid-time {
        height: 21px;
        margin-top: 15px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 21px;
        letter-spacing: 0;
        color: #888888;
      }
      .tags {
        height: 21px;
        .tag-item {
          float: left;
          text-align: left;
          font-size: 16px;
          color: #666;
          margin-right: 30px;
          .iconfont {
            color: #666;
          }
        }
      }
      .btn-edit {
        margin-top: 28px;
        .el-button {
          width: 120px;
          height: 44px;
          font-size: 16px;
        }
      }
    }
  }
  .marks {
    background: #FFB546;
    color: #fff;
    width: 32px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    border-radius: 12px;
    padding: 0 4px;
    margin-left: 10px;
    box-sizing: border-box;
    font-size: 12px;
  }
  .course-info-wrapper {
    display: flex;
    .leftWrap {
      background-color: #fff;
      position: fixed;
      width: 200px;
      height: calc(100% - 50px);
      background: #fff;
      .courseinfoTitle {
        padding: 20px;
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid #ebeef5;
        .imgWrap {
          position: relative;
          border-radius: 4px;
          img {
            display: block;
            width: 160px;
            height: 90px;
            border-radius: 4px;
          }
          .godoor {
            cursor: pointer;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 31px;
            text-align: center;
            line-height: 31px;
            background: rgba(0, 0, 0, 0.4);
            color: #fff;
            font-size: 14px;
            border-radius: 0 0 4px 4px;
          }
          .imgBottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 31px;
            text-align: center;
            line-height: 31px;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 14px;
            border-radius: 0 0 4px 4px;
          }
        }
        .titleWrap {
          margin-top: 10px;
          width: 100%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .course-info-menu {
      padding: 20px;
      left: 0;
      border: none;
      background-color: #fff;
      a {
        width: 160px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        margin-bottom: 10px;
        padding: 0 20px;
        box-sizing: border-box;
        i {
          font-size: 18px;
          color: #a6a6c1;
        }
        span {
          margin-left: 10px;
        }
      }
      a:hover {
        background-color: #f0f6ff;
        color: #333;
      }
      a.active-page-menu {
        border-radius: 4px;
        background-color: var(--change-color);
        color: #fff;
        i {
          color: #fff;
        }
      }
      .courseinfoTitle {
        height: 195px;
      }
    }
    .rightWrap {
      flex: 1;
      min-height: 830px;
      padding: 20px 20px 0 220px;
      border-left: 1px solid #eef1f3;
      box-sizing: border-box;
    }
    .page-content {
      border-radius: 3px;
      background-color: #fff;
    }
    .uxbfanhui1 {
      color: #787d84;
    }
  }
</style>
